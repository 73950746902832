import "./styles.scss";

import * as moment from "moment";

import { Alert, Button } from "antd";
import React, { Component } from "react";
import {
  getPartnerNameByNDKey,
  localeName,
} from "shared/constants";
import { fetchClassroomProgram } from "shared/actions/classroom-content";

import { CONFIG } from "../../config";
import Groups from "./components/Groups";
import UserDetails from "../UserDetails";
import { get } from "lodash";
import { getErrorMessage } from "shared/errors";
import { http } from "shared/services/http";
import qs from "query-string";

const backToClassroom = (e) => {
  window.location.replace(`${CONFIG.REACT_APP_CLASSROOM_URL}`);
};

class Assessment extends Component {
  state = {
    ndKey: null,
    loading: true,
    success: false,
    error: false,
    errorMessage: "",
    groups: null,
    invitation: null,
    selected: null,
    canBook: true,
    alwaysOpen: false,
  };

  getInitialState(overrideState = {}) {
    const queryParams = qs.parse(this.props.location.search) || {};
    const ndKey = get(queryParams, "nd_key");
    if (ndKey) {
      this.setState({ ndKey });
      document.title = getPartnerNameByNDKey(ndKey);
    } else {
      this.setState({
        error: true,
        errorMessage: "Are you sure you came here from your Udacity Classroom?",
      });
    }

    fetchClassroomProgram(ndKey)
      .then((nanodegree) => {
        this.setState({ nanodegree });
      }
    );

    http
      .get(`${CONFIG.REACT_APP_OMAC_API_URL}/assessments?nd_key=${ndKey}`)
      .then(({ body, response }) => {
        const errorCode = get(body, "errors[0].code");
        const groups = get(body, "groups", []).sort(function(a, b) {
          return new Date(a["start_date"]) - new Date(b["start_date"]);
        });
        const invitation = get(body, "invitations");

        if (errorCode || !response.ok) {
          return this.setState({
            loading: false,
            success: false,
            error: true,
            errorMessage: getErrorMessage(errorCode),
          });
        } else {
          const firstGroup = groups.length > 0 ? groups[0] : undefined;
          const invitationGroupId = get(invitation, "group_id", null);
          const alwaysOpen = get(body, "always_open", false);
          const canBook =
            !alwaysOpen &&
            (invitationGroupId || invitationGroupId === null) &&
            (groups.map((g) => g["group_id"]).includes(invitationGroupId) ||
              invitationGroupId === null)
              ? true
              : false;
          const shouldBookAlwaysOpen =
            alwaysOpen &&
            (!invitationGroupId ||
              (invitationGroupId &&
                invitationGroupId !== get(firstGroup, "group_id")));
          const alreadyBookedAlwaysOpen =
            alwaysOpen &&
            invitationGroupId &&
            invitationGroupId === get(firstGroup, "group_id");
          const links = alwaysOpen ? get(body, "links", []) : [];
          return this.setState({
            loading: false,
            success: false,
            error: false,
            groups,
            invitation: invitation || null,
            canBook,
            alwaysOpen,
            shouldBookAlwaysOpen,
            alreadyBookedAlwaysOpen,
            links,
            ...overrideState,
          });
        }
      })
      .catch((e) => {
        return this.setState({
          loading: false,
          success: false,
          error: true,
          errorMessage: getErrorMessage(),
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  componentDidMount() {
    this.getInitialState.call(this);
  }

  book() {
    const { invitation, groups, alwaysOpen, selected } = this.state;
    const invitationId = get(invitation, "invitation_id");
    const groupId = !alwaysOpen
      ? selected.group_id
      : get(groups, "[0].group_id");
    this.setState({ loading: true, error: false, success: false });

    return http
      .post(`${CONFIG.REACT_APP_OMAC_API_URL}/assessments`, {
        nd_key: this.state.ndKey,
        group_id: groupId,
        invitation_id: invitationId ? invitationId : undefined,
      })
      .then(({ body, response }) => {
        const errorCode = get(body, "errors[0].code");

        if (errorCode || !response.ok) {
          this.setState({
            loading: false,
            success: false,
            error: true,
            errorMessage: getErrorMessage(errorCode),
          });

          // Needed because cd12510 was misconfigured as a Course instead of a Nanodegree
          // so the page should be reloaded. But if it's not cd12510, then don't reload.
          // See https://udacity.atlassian.net/browse/ENT-4410
          if (this.state.ndKey === "cd12510") {
            document.location.reload();
          }
        }

        this.getInitialState.call(this, {
          loading: false,
          success: true,
          error: false,
        });

        document.location.reload();
      })
      .catch((e) => {
        return this.setState({
          loading: false,
          success: false,
          error: true,
          errorMessage: getErrorMessage(),
        });
      });
  }

  onSelectGroup(group) {
    this.setState({ selected: group });
  }

  getGroupFromInvitation(invitation) {
    const groupId = get(invitation, "group_id");
    const { groups } = this.state;
    const group = groupId
      ? groups.filter((g) => g["group_id"] === groupId)[0]
      : undefined;
    return group;
  }

  renderAssessmentLinks(links) {
    const linkButtons = [];
    let filteredLinks = [];
    if (Array.isArray(links) && links.length > 0) {
      links.forEach((link) => {
        if (link && get(link, "link") && get(link, "link").length > 0) {
          filteredLinks.push(link);
        }
      });
    }

    if (filteredLinks.length > 0) {
      if (filteredLinks.length === 1) {
        linkButtons.push(
          <div key={1}>
            <Button
              href={get(filteredLinks, "[0].link", "")}
              target="_blank"
              type="primary"
              size="large"
            >
              Start Assessment
            </Button>
          </div>
        );
      } else {
        filteredLinks.forEach((link) => {
          const localeShort = get(link, "locale", "en");
          const locale = localeName[localeShort] || localeShort;
          const url = get(link, "link", "");
          linkButtons.push(
            <div key={locale} className="mb-10">
              <Button
                href={url}
                target="_blank"
                type="primary"
                size="large"
              >{`Start ${locale} Assessment`}</Button>
            </div>
          );
        });
      }
    }
    return linkButtons;
  }

  render() {
    const {
      loading,
      error,
      success,
      errorMessage,
      groups,
      selected,
      canBook,
      invitation,
      alwaysOpen,
      shouldBookAlwaysOpen,
      alreadyBookedAlwaysOpen,
      links,
      nanodegree,
    } = this.state;
    const invitationGroup = this.getGroupFromInvitation(invitation);
    const groupsLength = groups && Array.isArray(groups) ? groups.length : 0;

    return (
      <div className="assessment">
        <div className="card-container">
          <div>
            <h1 className="no-margin">Book Your Assessment</h1>
            <h3 className="no-margin">{get(nanodegree, "title")}</h3>
            <p></p>
            <UserDetails className="mb-30" />
            {error && (
              <div>
                <Alert
                  className="mb-30"
                  message="Oops!"
                  description={errorMessage}
                  type="error"
                  closable
                  closeText="Go Back to Classroom"
                  onClose={backToClassroom}
                  showIcon
                />
              </div>
            )}
            {Array.isArray(groups) && groups.length === 0 && (
              <div>
                <Alert
                  className="mb-30"
                  message={`Sorry, We couldn't find any available dates.`}
                  description={errorMessage}
                  type="warning"
                  closable
                  closeText="Go Back to Classroom"
                  onClose={backToClassroom}
                  showIcon
                />
              </div>
            )}
            {!alwaysOpen &&
              invitationGroup &&
              canBook &&
              !success &&
              !loading && (
                <Alert
                  className="mb-30"
                  message={``}
                  description={`You have booked an assessment on ${moment(
                    invitationGroup["start_date"]
                  ).format(
                    "ddd, LL"
                  )}, to change it select one of the dates below`}
                  type="info"
                  showIcon
                />
              )}
            {!alwaysOpen && success === true && (
              <div>
                <Alert
                  className="mb-30"
                  message={`Success!`}
                  description={`You've booked your assessment successfully. You'll get an email shortly with the instructions.`}
                  type="success"
                  closable
                  closeText="Go Back to Classroom"
                  onClose={backToClassroom}
                  showIcon
                />
              </div>
            )}
            {alwaysOpen && success === true && (
              <div>
                <Alert
                  className="mb-30"
                  message={`Success!`}
                  description={`You've booked your assessment successfully.`}
                  type="success"
                  closable
                  closeText="Go Back to Classroom"
                  onClose={backToClassroom}
                  showIcon
                />
              </div>
            )}
            {!alwaysOpen && groupsLength > 0 && canBook && (
              <Groups
                groups={groups}
                onSelectGroup={this.onSelectGroup.bind(this)}
              />
            )}
            {!alwaysOpen && !canBook && (
              <Alert
                className="mb-30"
                message={`Oops!`}
                description={`Sorry, you have already booked an assessment.`}
                type="error"
                closable
                closeText="Go Back to Classroom"
                onClose={backToClassroom}
                showIcon
              />
            )}
            {!alwaysOpen && (
              <div>
                <Button
                  onClick={this.book.bind(this)}
                  disabled={loading || !selected || !canBook}
                  loading={loading}
                  type="primary"
                  size="large"
                >
                  Book Assessment
                </Button>
              </div>
            )}
            {alwaysOpen && shouldBookAlwaysOpen && (
              <div>
                <Button
                  onClick={this.book.bind(this)}
                  disabled={loading}
                  loading={loading}
                  type="primary"
                  size="large"
                >
                  Book And Start Assessment
                </Button>
              </div>
            )}
            {alwaysOpen && alreadyBookedAlwaysOpen && (
              <div>{this.renderAssessmentLinks.call(this, links)}</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Assessment;
