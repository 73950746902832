import cookie from 'react-cookies';

const getJWT = () => {
  const jwt = cookie.load('_jwt');
  if (jwt && jwt.length > 0) {
    return jwt;
  }
};

const headers = {
  Authorization: `Bearer ${getJWT()}`,
  'Content-Type': 'application/json',
};

export const http = {
  get: url => {
    return fetch(url, {
      headers,
      method: 'GET',
      credentials: 'include',
    }).then(response => {
      return response.json().then(body => {
        return {
          body,
          response,
        };
      });
    });
  },
  post: (url, body) => {
    return fetch(url, {
      headers,
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(body),
    }).then(response => {
      return response.json().then(body => {
        return {
          body,
          response,
        };
      });
    });
  },
};
