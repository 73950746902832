import React, {Component} from 'react';
import {Button, Steps, Icon} from 'antd';
import './styles.scss';

const Step = Steps.Step;

class DownloadCertificate extends Component {
  componentWillMount() {
    console.log(this.props);
  }

  getIcon(item) {
    if (item) {
      return <Icon type="check-circle" style={{color: '#029fcb'}} />;
    } else {
      return <Icon type="close-circle" style={{color: 'red'}} />;
    }
  }
  render() {
    const {phase, enrolled, expired, passed_labs, certificate, ndKey} = this.props;
    return (
      <div className="graduate">
        <div>
          <div>
            <h2>Checking eligiblity for {phase} certificate</h2>
          </div>
          <Steps className="step step_1" direction="vertical">
            <Step title="Enrolled" icon={this.getIcon(enrolled)} />
          </Steps>
          <Steps className="step step_2" direction="vertical">
            <Step title="Within Allowed Time" icon={this.getIcon(!expired)} />
          </Steps>
          <Steps className="step step_3" direction="vertical">
            <Step
              title="Passed Required Milestones"
              icon={this.getIcon(passed_labs)}
            />
          </Steps>
        </div>
        {certificate && enrolled && !expired && passed_labs ? (
          <Button
            className="download-btn"
            href={certificate}
            target="_blank"
            type="primary"
            block={true}
            size="large"
            icon="download">
            Download
          </Button>
        ) : null}
        {certificate === "" && enrolled && !expired && passed_labs && ndKey ? (
          <Button
            className="download-btn"
            href={"https://learn.udacity.com/graduation/" + ndKey}
            target="_blank"
            type="primary"
            block={true}
            size="large">
            Get Your Certificate Now
          </Button>
        ) : null}
      </div>
    );
  }
}

export default DownloadCertificate;
