import React, {Component} from 'react';
import {AppContext} from '../../AppProvider';
import {Form} from 'antd';
import './styles.scss';

const formItemLayout = {
  labelCol: {span: 3},
  wrapperCol: {span: 8},
};

class UserDetails extends Component {
  render() {
    return (
      <div className="link-wizard">
        <AppContext.Consumer>
          {context => (
            <div>
              <h2>You're currently logged in as:</h2>
              <div>
                <Form className="mb-30">
                  <Form.Item {...formItemLayout} label="Name">
                    <span className="ant-form-text">
                      {context.user.first_name} {context.user.last_name}
                    </span>
                  </Form.Item>
                  <Form.Item {...formItemLayout} label="Email">
                    <span className="ant-form-text">{context.user.email}</span>
                  </Form.Item>
                </Form>
              </div>
            </div>
          )}
        </AppContext.Consumer>
      </div>
    );
  }
}

export default UserDetails;
