const partnerNames = {
  arabcoders: "One Million Arab Coders",
  khawarizmicoders: "One Million Uzbek coders",
  jordaniancoders: "One Million Jordanian Coders",
  nfp: "Future Work is Digital",
  cori: "Center on Rural Innovation",
  "misk-future-seekers": "Misk",
  dct: "Digital Career Transformation",
  acc: "Accenture Scholarship Program",
  adsgtest: "Abu Dhabi School of Government Test",
  adsg: "Future Shapers",
  citi: "The Citi Scholarship Program",
  techup: "TechUp",
  edoworx: "EdoWorX",
  alx: "ALX Transforms Tech",
  default: "Udacity",
  https: "High Tech Transition Pathway Scholarship",
  awsdeepracer: "AWS DeepRacer",
};

const partnerNameByND = {
  "nd018-mena-nfp1": partnerNames["nfp"],
  "nd001-mena-nfp1": partnerNames["nfp"],
  "nd002-mena-nfp1": partnerNames["nfp"],
  "nd018-fwd-t1": partnerNames["nfp"],
  "nd000-fwd-t1": partnerNames["nfp"],
  "nd002-fwd-t1": partnerNames["nfp"],
  "nd0800-fwd-t1": partnerNames["nfp"],
  "nd0011-cori-mena-challenge": partnerNames["cori"],
  "nd018-cori-mena-challenge": partnerNames["cori"],
  "nd098-cori-mena-challenge": partnerNames["cori"],
  "nd789-fow1-mena-challenge": partnerNames["misk-future-seekers"],
  "nd098-dct-challenge": partnerNames["dct"],
  "nd018-dct-challenge": partnerNames["dct"],
  "nd578-dct-challenge": partnerNames["dct"],
  "nd008-acc": partnerNames["acc"],
  "nd000-acc": partnerNames["acc"],
  "nd018-test-adsg": partnerNames["adsgtest"],
  "nd018-adsg-fs": partnerNames["adsg"],
  "nd098-adsg-fs": partnerNames["adsg"],
  "nd088-adsg-fs": partnerNames["adsg"],
  "nd036-adsg-fs": partnerNames["adsg"],
  "nd000-citi": partnerNames["citi"],
  "nd331-techup-challenge": partnerNames["techup"],
  "nd144-techup-challenge": partnerNames["techup"],
  "nd578-techup-challenge": partnerNames["techup"],
  "nd002-edoworx-challenge": partnerNames["edoworx"],
  "nd0011-edoworx-challenge": partnerNames["edoworx"],
  "nd018-edoworx-challenge": partnerNames["edoworx"],
  "nd079-challenge-gm": partnerNames["https"],
  "nd002-alg-t1": partnerNames["alx"],
  "nd0011-alg-t1": partnerNames["alx"],
  "nd9990-alg-t1": partnerNames["alx"],
  "nd065-aws": partnerNames["awsdeepracer"],
  default: "Udacity",
};

export function getPartnerName(partnerKey) {
  if (partnerKey) {
    return partnerNames[partnerKey] || partnerNames["default"];
  } else {
    return partnerNames["default"];
  }
}

export function getPartnerNameByNDKey(ndKey) {
  if (ndKey) {
    return partnerNameByND[ndKey] || partnerNameByND["default"];
  } else {
    return partnerNameByND["default"];
  }
}

export const localeName = {
  en: "English",
  ar: "Arabic",
  fr: "French",
};
