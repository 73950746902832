const errors = {
  a404: 'Invalid Arabcoders user key',
  'a409-a': 'This partner account is already linked to a Udacity account',
  'a409-b': 'Udacity account is already linked to existing partner account',
  u900: 'Unexpected error occurred',
  'u409-b': 'Udacity account is already linked to existing partner user key',
  'u409-a': 'Partner account is already linked to another Udacity account',
  ugrad409: 'User has completed Milestone 2 but not Milestone 1',
  ugrad404: 'User is not enrolled in this track',
  ugrad417: "User's enrollment is revoked or expired",
  ureg409: 'You are already enrolled in this track',
  ureg420: 'You are not enrolled in this track',
  upolicy403: 'You are not allowed to be enrolled in this track',
  u404: 'No assessments found for this Nanodegree',
  u409: `Sorry, You haven't completed milestone 2 yet`,
  Assess417: `Sorry, We couldn't book this date. Try again later or contact our support.`,
  AssessmentInvitation404: `Please make sure you've booked an assessment first`,
  AssessmentUnfinishedMilestones404: `Please make sure you've finished the other milestones first`,
  default: 'Something went wrong.',
};

export function getErrorMessage(errorCode) {
  if (errorCode) {
    return errors[errorCode] || errors['default'];
  } else {
    return errors['default'];
  }
}
