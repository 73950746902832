import "./styles.scss";

import { Alert, Button } from "antd";
import React, { Component } from "react";

import { CONFIG } from "../../config";
import UserDetails from "../UserDetails";
import { get } from "lodash";
import { getErrorMessage } from "shared/errors";
import { getPartnerName } from "shared/constants";
import { http } from "shared/services/http";
import qs from "query-string";

class Link extends Component {
  state = {
    partnerKey: null,
    loading: false,
    success: false,
    error: false,
    errorMessage: "",
    linked: false,
  };

  allowedPartners = ["arabcoders", "jordaniancoders", "khawarizmicoders"];

  backToPartner() {
    if (this.state.partnerName === "arabcoders") {
      window.location.replace(CONFIG.REACT_APP_ARABCODERS_PROFILE);
    } else if (this.state.partnerName === "jordaniancoders") {
      window.location.replace(CONFIG.REACT_APP_JORDANIANCODERS_PROFILE);
    } else if (this.state.partnerName === "khawarizmicoders") {
      window.location.replace(CONFIG.REACT_APP_KHAWARIZMICODERS_PROFILE);
    } else {
      window.location.replace("https://www.udacity.com");
    }
  }

  componentWillMount() {
    // Populate state variables
    const queryParams = qs.parse(this.props.location.search) || {};
    // path from 6 is the beginning of the partner name
    const partnerName = this.props.match.params.partner_name;
    const partnerKey = get(queryParams, "partner_key");

    if (partnerName) {
      document.title = getPartnerName(partnerName);
    }

    if (partnerKey && partnerName) {
      this.setState({ partnerKey });
      this.setState({ partnerName });
    } else {
      this.setState({
        error: true,
        errorMessage:
          "Are you sure you came to this page from the right place?",
      });
    }

    // Check if Partner Name is valid
    if (!(this.allowedPartners.indexOf(partnerName) > -1)) {
      this.setState({
        error: true,
        errorMessage:
          "We don't recognize this partner. Please have the partner redirect you here properly.",
      });
    }

    // Check if logged in user alredy linked their account
    http
      .get(
        `${CONFIG.REACT_APP_OMAC_API_URL}/users/me?partner_name=` + partnerName
      )
      .then(({ body, response }) => {
        if (response.ok) {
          const linked = get(body, "linked");
          this.setState({ linked: linked });
        }
      });
  }

  linkAccount() {
    this.setState({ loading: true, error: false, success: false });

    return http
      .post(
        `${CONFIG.REACT_APP_OMAC_API_URL}/users/link/` + this.state.partnerName,
        {
          partner_key: this.state.partnerKey,
        }
      )
      .then(({ body, response }) => {
        const errorCode = get(body, "errors[0].code");

        if (errorCode || !response.ok) {
          return this.setState({
            loading: false,
            success: false,
            error: true,
            errorMessage: getErrorMessage(errorCode),
          });
        }

        return this.setState({
          loading: false,
          success: true,
          error: false,
        });
      })
      .catch((e) => {
        return this.setState({
          loading: false,
          success: false,
          error: true,
          errorMessage: getErrorMessage(),
        });
      });
  }

  render() {
    const { loading, error, success, errorMessage, linked } = this.state;
    var message;
    if (this.state.partnerName === "arabcoders") {
      message = (
        <h3 className="mb-50">
          Link your Udacity Account to an Arab Coders Account
        </h3>
      );
    } else if (this.state.partnerName === "jordaniancoders") {
      message = (
        <h3 className="mb-50">
          Link your Udacity Account to a Jordanian Coders Account
        </h3>
      );
    } else if (this.state.partnerName === "khawarizmicoders") {
      message = (
        <h3 className="mb-50">
          Link your Udacity Account to a Uzbek Coders Account
        </h3>
      );
    }
    return (
      <div className="link-account">
        <div className="card-container">
          <div>
            <h1 className="no-margin">Linking Accounts</h1>
            {message}
            <UserDetails className="mb-30" />
            {error ? (
              <Alert
                className="mb-30"
                message="Oops!"
                description={errorMessage}
                type="error"
                closable
                closeText="Go Back"
                onClose={this.backToPartner.bind(this)}
                showIcon
              />
            ) : null}
            {success ? (
              <Alert
                className="mb-30"
                message="You've successfully linked your account"
                type="success"
                closable
                showIcon
                closeText="Go Back"
                onClose={this.backToPartner.bind(this)}
              />
            ) : null}
            {linked ? (
              <Alert
                className="mb-30"
                message="Cannot Link"
                description="This Udacity account is already linked to another account."
                type="warning"
                showIcon
                closable
                closeText="Go Back"
                onClose={this.backToPartner.bind(this)}
              />
            ) : null}
            <Button
              onClick={this.linkAccount.bind(this)}
              loading={loading}
              type="primary"
              disabled={linked || error}
              size="large"
            >
              Link
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default Link;
