import "./styles.scss";

import { Alert, Button } from "antd";
import React, { Component } from "react";
import { getPartnerNameByNDKey } from "shared/constants";
import { fetchClassroomProgram } from "shared/actions/classroom-content";

import { CONFIG } from "../../config";
import DownloadCertificate from "components/Graduate/components/DownloadCertificate";
import UserDetails from "../UserDetails";
import { get } from "lodash";
import { getErrorMessage } from "shared/errors";
import { http } from "shared/services/http";
import qs from "query-string";

const backToClassroom = (e) => {
  window.location.replace(`${CONFIG.REACT_APP_CLASSROOM_URL}`);
};

class Graduate extends Component {
  state = {
    ndKey: null,
    loading: false,
    success: false,
    error: false,
    errorMessage: "",
    certificate: {},
  };

  componentWillMount() {
    const queryParams = qs.parse(this.props.location.search) || {};
    const ndKey = get(queryParams, "nd_key");
    if (ndKey) {
      this.setState({ ndKey });
      document.title = getPartnerNameByNDKey(ndKey);
    } else {
      this.setState({
        error: true,
        errorMessage: "Are you sure you came here from your Udacity Classroom?",
      });
    }
    fetchClassroomProgram(ndKey)
      .then((nanodegree) => {
        this.setState({ nanodegree });
      }
    );
  }

  graduate() {
    this.setState({ loading: true, error: false, success: false });

    return http
      .post(`${CONFIG.REACT_APP_OMAC_API_URL}/graduation`, {
        nd_key: this.state.ndKey,
      })
      .then(({ body, response }) => {
        const errorCode = get(body, "errors[0].code");

        if (errorCode || !response.ok) {
          return this.setState({
            loading: false,
            success: false,
            error: true,
            errorMessage: getErrorMessage(errorCode),
          });
        }

        return this.setState({
          loading: false,
          success: true,
          error: false,
          certificate: { ...body },
        });
      })
      .catch((e) => {
        return this.setState({
          loading: false,
          success: false,
          error: true,
          errorMessage: getErrorMessage(),
        });
      });
  }

  render() {
    const { loading, error, success, errorMessage, certificate, nanodegree, ndKey } = this.state;
    return (
      <div className="graduate">
        <div className="card-container">
          <div>
            <h1 className="no-margin">Get Your Certificate</h1>
            <h3 className="no-margin">{get(nanodegree, "title")}</h3>
            <p></p>
            <p className="mb-50">
              The name shown below will be printed on the certificate. To change
              your name please visit{" "}
              <a
                href={`${CONFIG.REACT_APP_CLASSROOM_URL}/settings/personal-info`}
              >
                Classroom Settings
              </a>{" "}
              before generating the certificate."
            </p>
            <UserDetails className="mb-30" />
            {error ? (
              <div>
                <Alert
                  className="mb-30"
                  message="Oops!"
                  description={errorMessage}
                  type="error"
                  closable
                  closeText="Go Back to Classroom"
                  onClose={backToClassroom}
                  showIcon
                />
                <Button
                  onClick={this.graduate.bind(this)}
                  loading={loading}
                  disabled={true}
                  type="primary"
                  size="large"
                >
                  Get Certificate
                </Button>
              </div>
            ) : null}
            {success ? (
              <DownloadCertificate className="mb-30" ndKey={ndKey} {...certificate} />
            ) : null}
            {!success && !error ? (
              <div>
                <Alert
                  className="mb-30"
                  description="Please make sure your full name is less than 30 Characters so that the certificate renders properly. Names on certificates can not be changed once issued."
                  type="warning"
                  showIcon
                />
                <Button
                  onClick={this.graduate.bind(this)}
                  loading={loading}
                  type="primary"
                  size="large"
                >
                  Get Certificate
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default Graduate;
