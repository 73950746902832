import React, {Component} from 'react';
import * as moment from 'moment';
import {Radio} from 'antd';
import './styles.scss';

class Groups extends Component {
  state = {
    selected: null,
  };

  onSelectGroup(e) {
    const group = e.target.value;

    this.setState(
      {
        selected: group,
      },
      () => this.props.onSelectGroup && this.props.onSelectGroup(group),
    );
  }

  render() {
    const {groups} = this.props;
    const {selected} = this.state;

    return (
      <div className="groups">
        <div>
          <h2>Please select one of the available dates below:</h2>
        </div>
        <div className="list">
          <Radio.Group onChange={this.onSelectGroup.bind(this)}>
            {groups &&
              groups.length > 0 &&
              groups.map((group, index) => (
                <Radio
                  value={group}
                  key={index}
                  className={
                    selected && selected.start_date === group.start_date
                      ? 'selected'
                      : ''
                  }
                  checked={
                    selected && selected.start_date === group.start_date
                  }>
                  {moment(group.start_date).format('ddd, LL')}
                </Radio>
              ))}
          </Radio.Group>
        </div>
      </div>
    );
  }
}

export default Groups;
