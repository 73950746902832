import "./styles.scss";

import { Alert, Button } from "antd";
import React, { Component } from "react";
import { getPartnerName } from "shared/constants";
import { fetchClassroomProgram } from "shared/actions/classroom-content";

import { CONFIG } from "../../config";
import UserDetails from "../UserDetails";
import { get } from "lodash";
import { getErrorMessage } from "shared/errors";
import { http } from "shared/services/http";
import qs from "query-string";

function getPartnerBasedHeading(partnerName, trackName, enrollmentDisabled) {
  if (enrollmentDisabled) {
    return `Enrollment into ${trackName} is closed`;
  }

  switch (partnerName) {
    case "jordaniancoders":
      return `Enroll into Jordanian-Coders ${trackName}`;
    case "khawarizmicoders":
      return `Enroll into Uzbek-Coders ${trackName}`;
    case "nfp":
      return `Enroll into Egypt FWD ${trackName}`;
    case "cori":
      return `Enroll into CORI ${trackName}`;
    case "dct":
      return `Enroll into DCT ${trackName}`;
    case "acc":
      return `Enroll into Accenture Scholarship ${trackName}`;
    case "adsgtest":
      return `Enroll into ADSG Test ${trackName}`;
    case "adsg":
      return `Enroll into ${trackName}`;
    case "citi":
      return `Enroll into ${trackName}`;
    case "techup":
      return `Enroll into TechUp ${trackName}`;
    case "edoworx":
      return `Enroll into EdoWorX ${trackName}`;
    case "alx":
      return `Enroll into ALX Transforms Tech ${trackName}`;
    case "https":
      return `Enroll into High Tech Transition Pathway Scholarship ${trackName}`;
    case "awsdeepracer":
      return `Enroll into AWS DeepRacer ${trackName}`;

    default:
      return "";
  }
}

class Enroll extends Component {
  state = {
    ndKey: null,
    loading: false,
    success: false,
    error: false,
    errorMessage: "",
  };

  allowedPartners = [
    "arabcoders",
    "jordaniancoders",
    "khawarizmicoders",
    "nfp",
    "cori",
    "misk-future-seekers",
    "dct",
    "acc",
    "adsgtest",
    "adsg",
    "citi",
    "techup",
    "edoworx",
    "https",
    "alx",
    "awsdeepracer",
  ];
  allowedNanodegrees = [
    "nd001-1mac-v2",
    "nd004-1mac-v2",
    "nd002-1mac-v2",
    "nd803-1mac-v2",
    "nd803-1mac-v2",
    "nd018-mena-nfp1",
    "nd001-mena-nfp1",
    "nd002-mena-nfp1",
    "nd000-fwd-t1",
    "nd002-fwd-t1",
    "nd018-fwd-t1",
    "nd0800-fwd-t1",
    "nd098-cori-mena-challenge",
    "nd018-cori-mena-challenge",
    "nd0011-cori-mena-challenge",
    "nd789-fow1-mena-challenge",
    "nd098-dct-challenge",
    "nd018-dct-challenge",
    "nd578-dct-challenge",
    "nd008-acc",
    "nd000-acc",
    "nd018-test-adsg",
    "nd018-adsg-fs",
    "nd098-adsg-fs",
    "nd088-adsg-fs",
    "nd036-adsg-fs",
    "nd000-citi",
    "nd331-techup-challenge",
    "nd144-techup-challenge",
    "nd578-techup-challenge",
    "nd002-edoworx-challenge",
    "nd0011-edoworx-challenge",
    "nd018-edoworx-challenge",
    "nd079-challenge-gm",
    "nd002-alg-t1",
    "nd0011-alg-t1",
    "nd9990-alg-t1",
    "nd065-aws",
  ];

  backToPartner() {
    window.location.replace(CONFIG.REACT_APP_CLASSROOM_URL);
  }

  componentWillMount() {
    // Populate state variables
    const queryParams = qs.parse(this.props.location.search) || {};
    // path from 6 is the beginning of the partner name
    const partnerName = this.props.match.params.partner_name;
    const ndKey = get(queryParams, "nd_key");
    const enrollmentDisabled = [
      "arabcoders",
      "misk-future-seekers",
      "jordaniancoders",
      "khawarizmicoders",
    ].includes(partnerName);

    if (ndKey && partnerName) {
      this.setState({
        ndKey,
        partnerName,
        enrollmentDisabled,
      });
      document.title = getPartnerName(partnerName);
    } else {
      this.setState({
        error: true,
        errorMessage:
          "Are you sure you came to this page from the right place?",
      });
    }

    fetchClassroomProgram(ndKey).then((nd) => {
      const partnerSubHeading = getPartnerBasedHeading(
        partnerName,
        nd.title,
        enrollmentDisabled
      );
      this.setState({ partnerSubHeading });
    });

    // Check if Partner Name is valid
    if (!(this.allowedPartners.indexOf(partnerName) > -1)) {
      this.setState({
        error: true,
        errorMessage:
          "We don't recognize this partner. Please have the partner redirect you here properly.",
      });
    }

    // Check if Nano-degree is valid
    if (!(this.allowedNanodegrees.indexOf(ndKey) > -1)) {
      this.setState({
        error: true,
        errorMessage:
          "We don't recognize this track. Please have the partner redirect you here properly.",
      });
    }
  }

  enrollUser() {
    this.setState({ loading: true, error: false, success: false });

    return http
      .post(`${CONFIG.REACT_APP_OMAC_API_URL}/enrollments/direct`, {
        nd_key: this.state.ndKey,
        partner_name: this.state.partnerName,
      })
      .then(({ body, response }) => {
        const errorCode = get(body, "errors[0].code");

        if (errorCode || !response.ok) {
          return this.setState({
            loading: false,
            success: false,
            error: true,
            errorMessage: getErrorMessage(errorCode),
          });
        }

        return this.setState({
          loading: false,
          success: true,
          error: false,
        });
      })
      .catch((e) => {
        return this.setState({
          loading: false,
          success: false,
          error: true,
          errorMessage: getErrorMessage(),
        });
      });
  }

  render() {
    const {
      loading,
      error,
      success,
      errorMessage,
      enrollmentDisabled,
      partnerSubHeading,
    } = this.state;

    return (
      <div className="enroll">
        <div className="card-container">
          <div>
            <h1 className="no-margin">Udacity Enrollment Gateway</h1>
            <h3 className="mb-50">{partnerSubHeading}</h3>
            <UserDetails className="mb-30" />
            {error ? (
              <Alert
                className="mb-30"
                message="Oops!"
                description={errorMessage}
                type="error"
                closable
                closeText="Go to classroom"
                onClose={this.backToPartner.bind(this)}
                showIcon
              />
            ) : null}
            {success ? (
              <Alert
                className="mb-30"
                message="You've successfully enrolled into this track"
                type="success"
                closable
                showIcon
                closeText="Go to classroom"
                onClose={this.backToPartner.bind(this)}
              />
            ) : null}
            {enrollmentDisabled ? (
              <Button type="primary" disabled size="large">
                Enrollment Closed
              </Button>
            ) : (
              <Button
                onClick={this.enrollUser.bind(this)}
                loading={loading}
                type="primary"
                disabled={error || success}
                size="large"
              >
                Enroll
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Enroll;
