import React, { Component } from "react";

import { CONFIG } from "./config";
import cookie from "react-cookies";

export const AppContext = React.createContext();

class AppProvider extends Component {
  state = {
    user: {},
    token: "",
    isAuthenticated: false,
    accountLinked: false,
  };

  getUserData = () => {
    const jwt = this.getJWT();
    if (jwt) {
      return fetch(`${CONFIG.REACT_APP_USER_API_URL}/me`, {
        credentials: "include",
      })
        .then((response) => {
          if (!response.ok) throw new Error();
          return response.json();
        })
        .then((user) => {
          return this.setState({
            user,
            token: jwt,
          });
        })
        .catch((e) => {
          return this.setState({
            user: {},
            isAuthenticated: false,
          });
        });
    }
  };

  getJWT = () => {
    const jwt = cookie.load("_jwt");
    if (jwt && jwt.length > 0) {
      return jwt;
    }
  };

  componentWillMount() {
    const jwt = this.getJWT();
    if (jwt) {
      this.setState({
        isAuthenticated: true,
        token: jwt,
      });

      this.getUserData();
    }
  }

  render() {
    return (
      <div>
        <AppContext.Provider value={this.state}>
          {this.props.children}
        </AppContext.Provider>
      </div>
    );
  }
}

export default AppProvider;
