import { get } from "lodash";
import { classConService } from 'shared/services/classroom-content-service';

// Nanodegree query
const ND_QUERY = `
query EMC_Nanodegree ($key: String) {
  nanodegree(key: $key) {
    title
  }
}`;

// Fallback default nanodegree values if fetching fails
const DEFAULT_ND = {
  title: "Udacity",
}

export async function fetchClassroomProgram(programKey) {
  // Currently, we only need to support nanodegree programs
  let query = ND_QUERY;
  return classConService.gql(query, { key: programKey }).then((res) => {
    const nd = get(res, "body.data.nanodegree")
    if (!nd) {
      return DEFAULT_ND;
    }
    return nd;
  });
}
