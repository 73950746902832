import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import logo from './logo.svg';
import './styles.scss';

class Header extends Component {
  render() {
    return (
      <header>
        <NavLink exact className="logo" to="/">
          <img src={logo} alt="Udacity Logo" />
        </NavLink>
      </header>
    );
  }
}

export default Header;
