import React, {Component} from 'react';
import './styles.scss';
import errorIcon from './icon-error.svg';

class NotFound extends Component {
  render() {
    return (
      <div className="not-found">
        <img src={errorIcon} alt="Error" />
        <h1>404 Not Found</h1>
        <h3>Sorry, we can't seem to find the page you are looking for.</h3>
      </div>
    );
  }
}

export default NotFound;
