import { CONFIG } from "../../config"
import { http } from "shared/services/http";

export const classConService = {
  post(path, data) {
    return http.post(`${CONFIG.REACT_APP_CLASSROOM_CONTENT_API_URL}${path}`, data)
  },
  gql(queryString, variables) {
    return this.post("/graphql", { query: queryString, variables })
  },
};
