import "./App.scss";

import AppProvider, { AppContext } from "AppProvider";
import React, { Component } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import Assessment from "components/Assessment";
import { CONFIG } from "./config";
import Enroll from "components/Enroll";
import Graduate from "components/Graduate";
import Header from "components/Header";
import Link from "components/Link";
import NotFound from "./components/NotFound";

class App extends Component {
  render() {
    return (
      <AppProvider>
        <AppContext.Consumer>
          {(context) =>
            context.isAuthenticated ? (
              <div className="App">
                <Router>
                  <div>
                    <Header />
                    <main role="main">
                      <Switch>
                        <Route
                          exact
                          path="/link/:partner_name"
                          component={Link}
                        />
                        <Route
                          exact
                          path="/enroll/:partner_name"
                          component={Enroll}
                        />
                        <Route exact path="/graduate" component={Graduate} />
                        <Route
                          exact
                          path="/assessment"
                          component={Assessment}
                        />
                        <Route component={NotFound} />
                      </Switch>
                    </main>
                  </div>
                </Router>
              </div>
            ) : (
              (window.location.href = `${CONFIG.REACT_APP_AUTH_URL}/sign-in?next=${window.location.href}`)
            )
          }
        </AppContext.Consumer>
      </AppProvider>
    );
  }
}

export default App;
